import './Stats.sass';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const Stats = ({ title, num, Icon, visit }) => {
  return (
    <div data-aos='fade-up'>
      <Icon />
      <p className='stats__title'>{title}</p>
      <p className='stats__num'>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <div>{isVisible ? <CountUp end={num} suffix={`${visit ? 'k' : ''}`} /> : 0}</div>
          )}
        </VisibilitySensor>
      </p>
    </div>
  );
};

export default Stats;

import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Messages.sass';
import TimeAgo from 'react-timeago';
import polishStrings from 'react-timeago/lib/language-strings/pl';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(polishStrings);

const Messages = () => {
  const [username, setUsername] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get('id');
  const messageRef = useRef(null);
  const bottomRef = useRef(null);

  useLayoutEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const fetchMessages = async () => {
    const messagesData = await (
      await fetch(`${process.env.REACT_APP_API_URL}/getMessages`, {
        method: 'POST',
        body: JSON.stringify({ ticketId }),
        redirect: 'follow',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
    ).json();
    console.log(messagesData);
    setMessages(messagesData.messages);
  };

  useEffect(() => {
    (async () => {
      const userData = await (
        await fetch(`${process.env.REACT_APP_API_URL}/getUser`, {
          method: 'POST',
          redirect: 'follow',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
      ).json();

      if (!userData.oauthToken || !userData.oauthUser)
        return (window.location = `${process.env.REACT_APP_API_URL}/loginRedirect`);
      setUsername(JSON.parse(userData.oauthUser).username);

      await fetchMessages();
    })();
  }, [ticketId]);

  const sendMessage = async (e) => {
    e.preventDefault();
    // 404 - ticket nie istnieje
    // 401 - brak zalogowania się
    // 403 - brak permisji
    await fetch(`${process.env.REACT_APP_API_URL}/sendMessage`, {
      method: 'POST',
      body: JSON.stringify({
        ticketId,
        content: messageRef.current.value,
      }),
      redirect: 'follow',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    messageRef.current.value = '';
    await fetchMessages();
  };

  console.log(messages);

  return (
    <div className='messages__container--outer'>
      <div className='messages__container--inner'>
        <div className='messages__header'>
          <p className='messages__title'>Ticket</p>
          <p className='messages__username'>
            Twój nick: <span>{username}</span>
          </p>
        </div>
        <div className='messages__messagesContainer'>
          {messages.map((m, i) => (
            <div key={i} className='message'>
              <span className='time-container'>
                [
                <TimeAgo className='time' date={m.date} formatter={formatter} />
                ]
              </span>{' '}
              {m.role && <span className='role'>{m.role.toUpperCase()}</span>}
              <span className='nick'>{m.authorName}</span>
              <span className='content'>: {m.content}</span>
            </div>
          ))}
          <div className='reset' ref={bottomRef} />
        </div>
        <form onSubmit={sendMessage} className='messages__form'>
          <input type='text' ref={messageRef} />
        </form>
      </div>
    </div>
  );
};

export default Messages;

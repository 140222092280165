import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Header from "./components/Header"
import Home from "./views/Home"
import ClonerDiscord from "./views/ClonerDiscord"
import ClonerEmoji from "./views/ClonerEmoji"
import RolesEmojiCloner from "./views/RolesEmojiCloner"
import "./index.css"
import EmojiChanger from "./views/EmojiChanger"
import Panel from "./views/Panel"
import Messages from "./views/Messages"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<BrowserRouter>
		<Header />
		<Routes>
			<Route path="/">
				<Route index element={<Home />} />
				<Route path="/clonerEmoji" element={<ClonerEmoji />} />
				<Route path="/rolesEmojiCloner" element={<RolesEmojiCloner />} />
				<Route path="/emojiChanger" element={<EmojiChanger />} />
				<Route path="/clonerDiscord" element={<ClonerDiscord />} />
				<Route path="/panel/messages" element={<Messages />} />
				<Route path="/panel" element={<Panel />} />
			</Route>
		</Routes>
	</BrowserRouter>
)
